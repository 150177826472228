import './distributiva_excursiones_julia.scss';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../src/components/Layout/Layout';
import SEO from '../src/helpers/seo';
import Heimdall from '../src/components/Heimdall/Heimdall';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Hod from '../src/components/Hod/Hod';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Freya } from '../src/components/Freya/Freya';
import tt from '../src/helpers/translation';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query escursionesJulia($locale: String!) {
    allExcursionesSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allExcursionesTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subTitle
          description
        }
      }
    }
    allExcursionesTitleImageBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allExcursionesBreadCrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allExcursionesShowCaseSubBlock(
      sort: { order: ASC, fields: position }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          title
          description
          summary
          call_to_action_copy
          call_to_action_path
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allExcursionesRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
            imgRich
          }
        }
      }
    }
    allExcursionesHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          body
          title
        }
      }
    }
    allExcursionesSingleParagraph(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          body
        }
      }
    }
  }
`;

class promocionExcursion extends Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allExcursionesTitleBlock.edges[0].node.title,
      subtitle: this.props.data.allExcursionesTitleBlock.edges[0].node.subTitle,
      image: {
        alt: this.props.data.allExcursionesTitleImageBlock.edges[0].node.alt,
        url: this.props.data.allExcursionesTitleImageBlock.edges[0].node.localImage.childImageSharp
          .fluid,
      },
    };
    const contentData = {
      title: tt('Elige tu paquete de entradas más transporte:', this.props.pageContext.locale),
      description: tt('Ven en tren a PortAventura World', this.props.pageContext.locale),
      h4: true,
    };

    const contentData2 = {
      title: tt('', this.props.pageContext.locale),
      description: tt(
        'Ven en autobús a PortAventura World con Julià Travel',
        this.props.pageContext.locale
      ),
      h4: true,
    };

    const cardData = {
      cards: this.props.data.allExcursionesShowCaseSubBlock.edges.map((item, index) => ({
        image: item.node.localImage.childImageSharp.fluid,
        altImage: item.node.image_alt,
        title: item.node.title,
        description: item.node.description,
        imgsize: 'large',
        index,
        buttons: {
          cta: item.node.call_to_action_path,
          size: 'small',
          color: 'orange',
          ctaText: item.node.call_to_action_copy,
          blank1: true,
        },
        fenrirData: {
          list: item.node.summary,
        },
      })),
    };
    const freyaData2 = {
      cards: cardData.cards.slice(3, 6),
    };

    const freyaData = {
      cards: cardData.cards.slice(0, 3),
    };
    const freyaData3 = {
      cards: cardData.cards.slice(6),
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <SEO
          title={this.props.data.allExcursionesSeoData.edges[0].node._0.title}
          description={this.props.data.allExcursionesSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
          imageRich={this.props.data.allExcursionesRichData.edges[0].node.rich_markup.imgRich}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="promocion-julia-content">
              <div className="text-area">
                <NeoAesir
                  locale={this.props.pageContext.locale}
                  pageName={this.props.pageContext.pageName}
                  title={this.props.data.allExcursionesBreadCrumbBlock.edges[0].node.name}
                />
                <Hod
                  data={this.props.data.allExcursionesRichData.edges[0].node.rich_markup}
                  url={this.props.pageContext.url}
                  img={
                    this.props.data.allExcursionesTitleImageBlock.edges[0].node.localImage
                      .childImageSharp.fluid
                  }
                />
                <div className="text-container">
                  <div
                    className="title-text"
                    dangerouslySetInnerHTML={{
                      __html: this.props.data.allExcursionesTitleBlock.edges[0].node.description,
                    }}
                  />
                </div>
              </div>
              <div className="text-container-2">
                <h3 className="title-text-1">
                  {this.props.data.allExcursionesHeaderBlock.edges[0].node.title}
                </h3>
                <div
                  className="title-text-2"
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.allExcursionesHeaderBlock.edges[0].node.body,
                  }}
                />
              </div>
              <H3 data={contentData} />
              <H4
                title=""
                description={tt(
                  'Ven gratis en tren con Rodalies de Catalunya*',
                  this.props.pageContext.locale
                )}
              />
              <Freya data={freyaData} />
              <H4 title={contentData2.title} description={contentData2.description} />
              <Freya data={freyaData2} />
              <H4
                title=""
                description={tt(
                  'Ven a PortAventura World en tren de larga distancia',
                  this.props.pageContext.locale
                )}
              />
              <p className="text-alone">
                {tt(
                  'Nuevos paquetes de entradas + tren. Ahorra comprando tu paquete y disfruta de PortAventura World viajando con la opción más segura, cómoda, económica y sostenible.',
                  this.props.pageContext.locale
                )}
              </p>
              <Freya data={freyaData3} />

              <div className="text-container-123">
                <div
                  className="title-text"
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.allExcursionesSingleParagraph.edges[0].node.body,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
promocionExcursion.propTypes = {
  promocionExcursion: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
};
/* eslint-enable react/require-default-props */

export default promocionExcursion;
